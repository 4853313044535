<template>
  <div>
    <s-crud
      title="Consumo Combustible"
      ref="gridFuelVoucher"
      excel
      edit
      add
      load
      sortable
      remove
      :filter="config.filter"
      :config="config"
      @rowSelected="rowSelected($event)"
      @save="saveFlv($event)"
      @load="dialogMassive()"
    >
      <template v-slot:filter>
        <v-container grid-list-xs>
          <v-row>
            <v-col offset-md="3" md="3">
              <s-date label="Fecha Inicio" v-model="config.filter.FlvDate"> </s-date>
            </v-col>
            <v-col md="3">
              <s-date label="Fecha End" v-model="config.filter.FlvDateEnd"> </s-date>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:FlvStatus="{ row }">
        <v-chip dark x-small :color="colorStatus(row)" style="font-size:10px;">
          {{ row.FlvStatusText }}
        </v-chip>
      </template>
      <template v-slot:default="{ item }">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="4" sm="3" md="2" lg="2">
              <s-date label="Fecha Consumo" v-model="item.FlvDate" />
            </v-col>
            <v-col cols="3" lg="3">
              <s-text label="N Vale" v-model="item.FlvDocumentNumber" autofocus />
            </v-col>
            <v-col cols="3">
              <s-select-definition label="Estado" :def="1142" :dgr="29" v-model="item.FlvStatus" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" lg="3">
              <s-select-vehicle label="Vehículo" v-model="item.VehID" />
            </v-col>

            <v-col cols="3" sm="2">
              <s-text label="Cant Gal" v-model="item.FlvQuantity" />
            </v-col>
            <v-col cols="2" md="1">
              <s-select-definition label="Tipo" abbr :def="1138" v-model="item.TypeFuel" />
            </v-col>
            <v-col cols="3" sm="2">
              <s-text label="Precio Gal" v-model="item.FlvPrice" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </s-crud>
    <div v-if="showDialogLoad">
      <v-dialog v-model="showDialogLoad" width="80%">
        <s-toolbar color="primary" dark label="Carga masiva de vales combustible" @close="showDialogLoad = false" close></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <s-import-excel @loaded="loaded($event)" :headers="config.headersMassive" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import _sFuelVoucher from "@/services/Management/FuelVoucherService";
import SSelectVehicle from "@/components/Utils/Vehicle/SSelectVehicle.vue";
import _sDefinition from "@/services/General/DefinitionService";
import _sVehicle from "@/services/Management/VehicleService";

export default {
  components: { SSelectVehicle },
  data: () => ({
    config: {
      service: _sFuelVoucher,
      filter: {
        FlvDate: null,
        FlvDateEnd: null,
      },
      model: {
        FlvID: "ID",
        FlvDate: "date",
        FlvDocumentNumber: "string",
        VehID: "int",
        FlvQuantity: "decimal",
        TypeFuel: "int",
        FlvPrice: "decimal",
        FlvStatus: "int",
        SecStatus: "int",
      },
      headers: [
        {
          text: "Fecha",
          value: "FlvDate",
          align: "end",
        },
        {
          text: "Vale",
          value: "FlvDocumentNumber",
          align: "end",
        },
        {
          text: "Vehículo",
          value: "VehPlate",
          align: "center",
          sortable: false,
        },
        {
          text: "Gal.",
          value: "FlvQuantity",
          align: "end",
          sortable: false,
        },
        {
          text: "Combustible",
          value: "TypeFuelText",
          sortable: false,
        },
        {
          text: "Precio",
          value: "FlvPrice",
          align: "end",
          sortable: false,
        },

        {
          text: "Estado",
          value: "FlvStatus",
          align: "center",
          sortable: true,
        },
      ],
      headersMassive: [
        {
          text: "Fecha",
          value: "FlvDate",
          column: "FECHA",
          date: true,
        },
        { text: "Vale", value: "FlvDocumentNumber", column: "VALE" },
        { text: "Placa", value: "VehPlate", column: "PLACA" },
        { text: "Galones", value: "FlvQuantity", column: "GALONES" },
        { text: "Tipo", value: "TypeFuelText", column: "TIPO" },
        { text: "Precio", value: "FlvPrice", column: "PRECIO" },
      ],
    },
    showDialogLoad: false,
    selected: {},
    dialog: false,
    flvID: 0,
    listVehicle: [],
    listTypeFuel: [],
  }),
  created() {
    _sDefinition.definitiongroup(1138, 0, this.$fun.getUserID()).then((r) => {
      this.listTypeFuel = r.data;
    });
    _sVehicle.list(0, this.$fun.getUserID()).then((r) => {
      this.listVehicle = r.data;
    });
  },
  methods: {
    rowSelected(item) {
      this.selected = item;
    },
    colorStatus(item) {
      let color;
      if (item.FlvStatus == 1) color = "warning";
      else color = "success";

      return color;
    },
    dialogMassive() {
      this.showDialogLoad = true;
    },
    validatedFlv(item) {
      let isValid = false;
      let message = "";

      isValid = item.FlvDate.length > 0;
      if (!isValid) message = "Ingrese Fecha de Consumo";
      else {
        isValid = item.FlvDocumentNumber.length > 0;
        if (!isValid) message = "Ingrese Vale de Consumo";
        else {
          isValid = item.VehID > 0;
          if (!isValid) message = "Seleccione Vehículo";
          else {
            isValid = item.FlvQuantity > 0;
            if (!isValid) message = "Ingrese Cantidad de Combustible";
            else {
              isValid = item.TypeFuel > 0;
              if (!isValid) message = "Seleccione Tipo de Combustible";
              else {
                isValid = item.FlvPrice > 0;
                if (!isValid) message = "Ingrese Precio por galon del Combustible";
              }
            }
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveFlv(item) {
      if (this.validatedFlv(item)) {
        item.save();
      }
    },
    loaded(table) {
      this.$fun.alert("Seguro de Cargar Datos?", "question").then((r) => {
        if (r.value) {
          table.map((item) => {
            item.FlvStatus = 1;
            item.TypeFuel = this.listTypeFuel.find((x) => x.DedAbbreviation == item.TypeFuelText)?.DedValue;
            item.VehID = this.listVehicle.find((x) => x.VehPlate == item.VehPlate)?.VehID;
          });

          _sFuelVoucher.saveMassive(table, this.$fun.getUserID()).then((r) => {
            this.showDialogLoad = false;
            this.$fun.alert("Agregados Correctamente", "success");
          });
        }
      });
    },
  },
  computed: {},
};
</script>
