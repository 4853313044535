import Service from "../Service";

const resource = "fuelvoucher/";

export default {
  save(veh, requestID) {
    return Service.post(resource + "save", veh, {
      params: { requestID: requestID },
    });
  },
  saveMassive(list, requestID) {
    return Service.post(resource + "savemassive", list, {
      params: { requestID: requestID },
    });
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  get(veh, requestID) {
    return Service.post(resource, veh, {
      params: { requestID: requestID },
    });
  },
};
